.tp-bullets {
  width: 340px !important;
  height: 3px !important;
  transform: translate(-170px, -33px) !important;
}

.light-bars .tp-bullet {
  width: 60px !important;
  height: 3px !important;
}

.light-bars .tp-bullet:nth-child(2) {
  left: 70px !important;
}

.light-bars .tp-bullet:nth-child(3) {
  left: 140px !important;
}

.light-bars .tp-bullet:nth-child(4) {
  left: 210px !important;
}

.light-bars .tp-bullet:nth-child(5) {
  left: 280px !important;
}

.tparrows {
  width: 50px !important;
  height: 50px !important;
  background-color: rgba(255, 255, 255, 0) !important;
  position: relative !important;
}

.tparrows:nth-child(2) {
  transform: translate(28px, 25px) !important;
}

.tparrows:nth-child(1) {
  transform: translate(-80px, -25px) !important;
}

.tparrows:before {
  content: "" !important;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  width: 30px !important;
  height: 30px !important;
  font-size: 40px !important;
  border-right: 3px solid #cbd5e0 !important;
  border-bottom: 3px solid #cbd5e0 !important;
  border-radius: 2px !important;
}

.tparrows.tp-rightarrow:before {
  transform: translate(-50%, -50%) rotate(-40deg) !important;
}

.tparrows.tp-leftarrow:before {
  transform: translate(-50%, -50%) rotate(136deg) !important;
}
